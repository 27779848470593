import React from 'react'

import MarkdownContent from '@/components/atoms/MarkdownContent'
import Image from '@/components/atoms/Image'
import Heading from '@/components/atoms/Heading'

import * as styles from './styles.module.css'

const OurPortfolio: React.FC<OurStoryPortfolioType> = ({ image: imageData, features, heading }) => {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<Heading level={3} variant="bold" className={styles.heading}>
					{heading}
				</Heading>
				<div className={styles.featuresWrapper}>
					{features?.map((feature: RichTextType) => (
						<div key={feature.id} className={styles.feature}>
							<MarkdownContent content={feature.content} />
						</div>
					))}
				</div>
				<Image image={imageData} className={styles.image} />
			</div>
		</section>
	)
}

export default OurPortfolio
