import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import OurStory from '@/components/organisms/OurStory'
import OurPortfolio from '@/components/organisms/OurPortfolio'
import OurPeople from '@/components/organisms/OurPeople'
import Testimonial from '@/components/organisms/Testimonial'
import OurSuccess from '@/components/organisms/OurSuccess'
import Hero from '@/components/organisms/Hero'

interface Props {
	data?: {
		content: {
			seo?: SeoMetagagsType
			section_our_story?: OurStoryType
			section_our_portfolio: OurStoryPortfolioType
			section_our_people?: OurPeopleType
			section_testimonial?: TestimonialType
			section_our_success?: OurSuccessType
			section_hero?: HeroType
		}
	}
}

const CompanyPage: React.FC<Props> = ({ data }) => {
	const { content } = data || {}

	return (
		<Layout>
			<div className="background company">
				<Seo {...content?.seo} />
				<Hero {...content?.section_hero} />
				<OurStory {...content?.section_our_story} />
				<Testimonial {...content?.section_testimonial} />
				<OurPortfolio {...content?.section_our_portfolio} />
				<OurSuccess {...content?.section_our_success} />
				<OurPeople {...content?.section_our_people} />
			</div>
		</Layout>
	)
}

export const CompanyPageQuery = graphql`
	query {
		content: strapiPageCompany {
			section_our_story {
				heading
				id
				left_side_text
				right_side_image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				bottom_text
			}

			section_hero {
				background {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				bg_dark_level
				content {
					data {
						content
					}
				}
			}

			section_our_portfolio {
				features {
					content {
						data {
							content
						}
					}
					id
				}
				heading
				id
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}
			section_testimonial {
				author
				background_image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				content
			}

			section_our_people {
				heading
				content
				image {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
			}

			section_our_success {
				features {
					content {
						data {
							content
						}
					}
					id
				}
				heading
				id
			}

			seo {
				description
				title
			}
		}
	}
`

export default CompanyPage
