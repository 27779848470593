import React from 'react'

import MarkdownContent from '@/components/atoms/MarkdownContent'
import Heading from '@/components/atoms/Heading'
import Image from '@/components/atoms/Image'

import * as styles from './styles.module.css'

export default function OurPeople({ content, heading, image: imageData }: OurPeopleType) {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={styles.inner}>
					<Heading level={3} variant="bold">
						{heading}
					</Heading>

					<div className={styles.mdContentWrapper}>
						<MarkdownContent content={content} />
					</div>

					<div className={styles.imageWrapper}>
						<Image image={imageData} alt="Our People" />
						<div className={styles.triangle} />
					</div>
				</div>
			</div>
		</section>
	)
}
