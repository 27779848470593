import React from 'react'

import Heading from '@/components/atoms/Heading'
import MarkdownContent from '@/components/atoms/MarkdownContent'

import * as styles from './styles.module.css'

function OurSuccess({ heading, features }: OurSuccessType) {
	return (
		<section className={styles.wrapper}>
			<div className={`container ${styles.divider} `}>
				<Heading level={3} variant="bold">
					{heading}
				</Heading>
				<div className={styles.featuresArea}>
					{features?.map((feature) => (
						<div key={feature.id} className={styles.featureCard}>
							<MarkdownContent content={feature.content} />
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default OurSuccess
