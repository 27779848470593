import React from 'react'
import Heading from '@/components/atoms/Heading'
import Image from '@/components/atoms/Image'

import * as styles from './styles.module.css'

const OurStory: React.FC<OurStoryType> = ({ right_side_image, bottom_text, heading, left_side_text }) => {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={styles.heading}>
					<Heading level={3} variant="bold">
						{heading}
					</Heading>
				</div>

				<div className={styles.textImageArea}>
					<div>
						<p>{left_side_text}</p>
					</div>

					<div className={styles.imageWrapperInner}>
						<Image image={right_side_image} />
						<div className={styles.triangle} />
					</div>
				</div>
				<div>
					<p>{bottom_text}</p>
				</div>
			</div>
		</section>
	)
}

export default OurStory
